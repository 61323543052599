<template>
<!-- 首页导航 -->
 <div class="nav">
    <div class="menu">
      <ul id="nav">
        <li class="item" v-for="(item, index) in navigationBar" :key="index" >
          <router-link :to="item.to" exact="">{{ item.title }}</router-link>
        </li>
		<li class="item"><a href="http://bbs.jsrailway.com.cn:88/" target="_blank">铁路论坛</a></li>
        <li class="item"><a href="http://oa.jsrailway.com.cn:8080/" target="_blank">OA办公</a></li>
	  </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      navigationBar: [
        { title: "首页", to: "/index/" },
        { title: "铁路概况", to: "/list/tlgk" },
        { title: "机构设置", to: "/list/jgsz" },
        { title: "政务动态", to: "/list/zwdt" },
        { title: "党建动态", to: "/list/djdt" },
        { title: "法治宣传", to: "/list/fzxc" },
        { title: "为您服务", to: "/list/wnfw" },
        { title: "职工之家", to: "/#/" },
        // { title: "铁路论坛", to: "http://bbs.jsrailway.com.cn:88/" },
        // { title: "OA办公", to: "http://oa.jsrailway.com.cn:8080/" },
      ],
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
// 导航
.nav {
  width: 100%;
  background-size: 100%;
  font-family: Regular;
  .menu {
    max-width: 1220px;
    margin: 0px auto 0;
    #nav {
      border-bottom: #006CC6 solid 4px;
      display: flex;
      cursor: pointer;
      .item {
        width: 122px;
        height: 52px;
        line-height: 52px;
        background-color: rgba(255, 255, 255, 1);
        text-align: center;
        font-size: 20px;
		border-right: solid #efefef 1px;
        a {
          color: rgba(51, 51, 51, 1);
          text-decoration: none;
          height: 52px;
        }
      }
      .item:last-of-type {
        
        border-radius: 0px 4px 4px 0px;
		
      }
      .item:first-of-type {
        border-radius: 4px 0px 0px 4px;
		border-left: solid #efefef 1px;
      }
     
    }
  }
}
.router-link-active{
        background: linear-gradient(
          180deg,
          rgba(24, 90, 198, 1) 0%,
          rgba(31, 74, 153, 1) 100%
        );
        color: #fff !important;
		width: 100%;
		display: inline-block;
        text-align: center;
		
  }
</style>