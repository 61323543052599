<template>
	<div class="main">
		<!-- 两侧浮标 -->
		<!-- <div class="img-fixed1">
			 
			<img src="../assets/img/fixed1.jpg" alt="">
		</div> -->
		<!-- <div class="img-fixed2">
			 
			<img src="../assets/img/fixed2.jpg" alt="">
		</div> -->
			<indexTop ></indexTop>
			<!-- 导航 -->
			<indexNavigation class="indexNav"></indexNavigation>
		
		
		<!-- 内容 -->
		<div class="content">
			<div class="content-item1">
				<div class="swiper-box">
					<!-- 轮播图 -->
					<div class="swiper">
						<div id="app">
							<div class="SwiperBox" ref="SwiperBox" @mouseenter="MouseFun('移入')"
								@mouseleave="MouseFun('移出')">
								<!-- 图片 -->
								<div class="imgBox" :style="{left:`-${leftVal}px`,transition:`${ition}s`}">
									<!-- <img alt="" :src="item.url" v-for="(item,index) in swiperList" :key="item.id"
										@click="toDetail(item.id)"> -->
									<!-- 复制第一张放到最后,以实现无缝无线循环滚动效果 -->
									<!-- <img :src="firstUrl" alt=""> -->
                               <div v-for="(item,index) in swiperList" :key="item.id" @click="toDetail(item.id)">
                               	<img :src="item.url" alt="">
								<div class="title" >
								  <div class="text">{{item.title}}</div>
								  <div class="date">{{item.date}}</div>
								</div>
                               </div>
							   <div>
								   <img :src="firstUrl" alt="">
								   <div class="title" >
								     <div class="text">{{firstTitle}}</div>
								     <div class="date">{{firstDate}}</div>
								   </div>
							   </div>
								</div>
								

								<!-- 左箭头按钮 -->
								<div class="leftBtn" @click="throttle(PrevFun)">&larr;</div>
								<!-- 右箭头按钮 -->
								<div class="rightBtn" @click="throttle(NextFun)">&rarr;</div>
								<!-- 下方指示点容器 -->
								<div class="instBox">
									<div @click="instFun(index)" v-for="(item,index) in swiperList.length" :key="index"
										:class="['inst',index==imgShow?'instActv':'']">
									</div>
								</div>
							</div>
						</div>
						
						<!-- <div class="block" >
                <el-carousel @change="handChange" :interval="3000" ref="carousel"> 
                  <el-carousel-item v-for="item in swiperList" :key="item.id">
                    <img alt="" :src="item.url" @click="toDetail(item.id)">
                        
                    <div class="title" @click="toDetail(item.id)">
                      <div class="text">{{item.title}}</div>
                      <div class="date">{{item.date}}</div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div> -->

					</div>
				</div>
				<!-- 新闻 -->
				<div class="news">
					<div class="title">
						<div class="newsphoto">
							<img src="../assets/img/circle.png" alt="" />
							<div>图片新闻</div>
						</div>
						<div class="more" @click="toList('tpxw')">
							<div>查看更多</div>
							<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
						</div>
					</div>
					<ul class="newsGroup">
						<li v-for="li in picNewsList" :key="li.id" class="item" @click="toDetail(li.id)">
							<div class="headline">· {{li.title}}</div>
							<div class="date">{{li.createTime}}</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="content-item2">
				<div class="list1">
					<div class="title">
						<div class="newsphoto">
							<img src="../assets/img/circle.png" alt="" />
							<div>通知公告</div>
						</div>
						<div class="more" @click="toList('tzgg')">
							<div>查看更多</div>
							<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
						</div>
					</div>
					<ul class="headline-group">
						<li v-for="li in tzggList" :key="li.id" class="item" @click="toDetail(li.id)">· {{li.title}}
						</li>
					</ul>
				</div>

				<div class="list2">
					<div class="title">
						<div class="newsphoto">
							<img src="../assets/img/circle.png" alt="" />
							<div>政务动态</div>
						</div>
						<div class="more" @click="toList('zwdt')">
							<div>查看更多</div>
							<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
						</div>
					</div>
					<ul class="headline-group">
						<li v-for="li in zwdtList" :key="li.id" class="item" @click="toDetail(li.id)">· {{li.title}}
						</li>
					</ul>
				</div>
				<div class="serve">
					<div class="title">
						<img src="../assets/img/circle.png" alt="" />
						<div>为您服务</div>
					</div>
					<ul class="serveGroup">
						<li v-for="li in wnfwList" :key="li.id" class="item" @click="toDetail(li.id)">
							<img src="../assets/img/serve.png" alt="" />
							<div class="text">{{li.title}}</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="banner">
				<!-- <img src="../assets//img/banner.png" alt="" /> -->
			</div>
			<div class="content-item3">
				<div class="box">
					<div class="list">
						<div class="title">
							<div class="newsphoto">
								<img src="../assets/img/circle.png" alt="" />
								<div>党建动态</div>
							</div>
							<div class="more" @click="toList('djdt')">
								<div>查看更多</div>
								<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
							</div>
						</div>
						<ul class="headline-group">
							<li v-for="li in djdtList" :key="li.id" class="item" @click="toDetail(li.id)">· {{li.title}}
							</li>
						</ul>
					</div>

					<div class="list">
						<div class="title">
							<div class="newsphoto">
								<img src="../assets/img/circle.png" alt="" />
								<div>法治宣传</div>
							</div>
							<div class="more" @click="toList('fzxc')">
								<div>查看更多</div>
								<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
							</div>
						</div>
						<ul class="headline-group">
							<li v-for="li in fzxcList" :key="li.id" class="item" @click="toDetail(li.id)">· {{li.title}}
							</li>
						</ul>
					</div>
					<div class="list">
						<div class="title">
							<div class="newsphoto">
								<img src="../assets/img/circle.png" alt="" />
								<div>纪检监察</div>
							</div>
							<div class="more" @click="toList('jjjc')">
								<div>查看更多</div>
								<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
							</div>
						</div>
						<ul class="headline-group">
							<li v-for="li in jjjcList" :key="li.id" class="item" @click="toDetail(li.id)">· {{li.title}}
							</li>
						</ul>
					</div>
					<div class="list">
						<div class="title">
							<div class="newsphoto">
								<img src="../assets/img/circle.png" alt="" />
								<div>文明创建</div>
							</div>
							<div class="more" @click="toList('wmcj')">
								<div>查看更多</div>
								<img src="../assets/img/mb-angle-circle-right@1x.png" alt="" />
							</div>
						</div>
						<ul class="headline-group">
							<li v-for="li in wmcjList" :key="li.id" class="item" @click="toDetail(li.id)">· {{li.title}}
							</li>
						</ul>
					</div>
				</div>
				<div class="serve">
					<div class="title">
						<img src="../assets/img/circle.png" alt="" />
						<div>机构设置</div>
					</div>
					<ul class="serveGroup">
						<li v-for="li in jgszList" :key="li.id" class="item" @click="toDetail(li.id)">
							<img src="../assets/img/organization.png" alt="" />
							<div class="text">{{li.title}}</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<bottom></bottom>
	 
	  
	 
	</div>
</template>

<script>
	import Bottom from '../components/Bottom.vue';
	import IndexNavigation from "../components/IndexNavigation.vue";
	import indexTop from "../components/indexTop.vue"
	import newsApi from "@/api/news";

	export default {
		components: {
			IndexNavigation,
			indexTop,
			Bottom,

		},
		data() {
			return {
				firstUrl: "",
				firstTitle:"",
				firstDate:"",
				leftVal: 0, // 轮播图盒子的偏移值
				flag: true, // 用来节流防止重复点击
				start: null, // 自动执行下一张定的时器
				imgWidth: 450, // 在这里填写你需要的图片宽度
				ition: 0.8, // 设置轮播图过度时间
				imgShow: 0, // 表示当前显示的图片索引
				carouseId: 0,
				swiperList: [],
				picNewsList: [],
				tzggList: [],
				zwdtList: [],
				djdtList: [],
				fzxcList: [],
				jjjcList: [],
				wmcjList: [],
				wnfwList: [],
				jgszList: [],
			};
		},
		methods: {
			handChange(index) {
				this.$refs.carousel.setActiveItem(index);
				this.carouseId = index
			},
			//焦点图
			listSwiper() {
				var self = this;

				var formData = new FormData();
				formData.append("pageSize", 4);
				newsApi.swiperList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.swiperList = jsonData;
						self.firstUrl = self.swiperList[0].url;
						self.firstTitle=self.swiperList[0].title;
						self.firstDate=self.swiperList[0].firstDate;
					})
					.catch((error) => {
						self.$message.error("焦点图获取数据发生错误！");
					});
			},
			//图片新闻
			listTPXW() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "ee0ea72a-75bf-4970-ad34-11e3132bc6a3");
				formData.append("pageSize", 8);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.picNewsList = jsonData;
					})
					.catch((error) => {
						self.$message.error("图片新闻获取数据发生错误！");
					});
			},
			//通知公告
			listTZGG() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "de3958c2-0010-4009-b7e1-15bfdcc60cef");
				formData.append("pageSize", 6);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.tzggList = jsonData;
					})
					.catch((error) => {
						self.$message.error("通知公告获取数据发生错误！");
					});
			},
			//政务动态
			listZWDT() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "939fbcca-8c31-478f-a7dc-6c649648164e");
				formData.append("pageSize", 6);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.zwdtList = jsonData;
					})
					.catch((error) => {
						self.$message.error("政务动态获取数据发生错误！");
					});
			},
			//党建动态
			listDJDT() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "f57b8b67-db9a-4110-bc9b-7ec382a07954");
				formData.append("pageSize", 5);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.djdtList = jsonData;
					})
					.catch((error) => {
						self.$message.error("党建动态获取数据发生错误！");
					});
			},
			//法治宣传
			listFZXC() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "39599bb3-78e9-4e84-99b5-2d6a5c1c40e3");
				formData.append("pageSize", 5);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.fzxcList = jsonData;
					})
					.catch((error) => {
						self.$message.error("法治宣传获取数据发生错误！");
					});
			},
			//纪检监察
			listJJJC() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "8e7f6d22-2b5b-4efc-b354-dbcddb46a8b3");
				formData.append("pageSize", 5);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.jjjcList = jsonData;
					})
					.catch((error) => {
						self.$message.error("纪检监察获取数据发生错误！");
					});
			},
			//文明创建
			listWMCJ() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "a4870553-44e0-4172-91db-263cedf5938f");
				formData.append("pageSize", 5);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.wmcjList = jsonData;
					})
					.catch((error) => {
						self.$message.error("文明创建获取数据发生错误！");
					});
			},
			//为您服务
			listWNFW() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "7288376a-b582-4e9a-98fa-c218e35b5c30");
				formData.append("pageSize", 5);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.wnfwList = jsonData;
					})
					.catch((error) => {
						self.$message.error("为您服务获取数据发生错误！");
					});
			},
			//机构设置
			listJGSZ() {
				var self = this;
				var formData = new FormData();
				formData.append("typeId", "86e29bf7-9e8f-4a28-9361-1001705d3dc6");
				formData.append("pageSize", 5);
				newsApi.tabList(formData).then(function(response) {
						var jsonData = response.data.data;
						self.jgszList = jsonData;
					})
					.catch((error) => {
						self.$message.error("机构设置获取数据发生错误！");
					});
			},
			toDetail(id) {
				let pathInfo = this.$router.resolve({
					path: '/detail',
					query: {
						id: id
					}
				})
				
				window.open(pathInfo.href, '_blank');
			},
			toList(str) {
				this.$router.push("/list/" + str)
			},
			// 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
			MouseFun(type) { // 停止定时器            // 重新执行定时器
				type == '移入' ? clearTimeout(this.start) : this.奥力给()
			},
			// 此为自动轮播定时器
			奥力给() {
				this.start = setInterval(() => {
					this.NextFun()
				}, 1500)
			},
			// 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
			throttle(fun) {
				if (this.flag) {
					this.flag = false;
					fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
					setTimeout(() => {
						this.flag = true;
					}, 1200); // 节流间隔时间
				}
			},
			// 上一张
			PrevFun() {
				
				if (this.leftVal == 0) { // 判断显示的图片 是 第一张时执行
					// this.swiperList.length是指循环图片数组的图片个数
					this.ition = 0 // 将过渡时间变成0，瞬间位移到最后一张图
					this.imgShow = this.swiperList.length - 1 // 将高亮小点改为最后一张图
					this.leftVal = (this.swiperList.length) * this.imgWidth // 瞬间移动
					setTimeout(() => { // 通过延时障眼法,归原过渡时间,执行真正的“上一张”函数
						this.ition = 0.8
						this.leftVal -= this.imgWidth
					}, this.ition * 1000)
				} else { // 判断显示的图片 不是 第一张时执行
					this.ition = 0.8
					this.leftVal -= this.imgWidth
					this.imgShow--
				}
				
			},
			// 下一张
			NextFun() {
				
				if (this.leftVal == (this.swiperList.length - 1) * this.imgWidth) { // 判断显示的图片 是 最后一张时执行
					this.ition = 0.8
					this.leftVal += this.imgWidth
					this.imgShow = 0
					setTimeout(() => {
						this.ition = 0
						this.leftVal = 0
					}, this.ition * 1000)
				} else { // 判断显示的图片 不是 最后一张时执行
					this.ition = 0.8
					this.leftVal += this.imgWidth
					this.imgShow++
				}
			},
			// 点击小圆点
			instFun(index) {
				this.ition = 0.8
				this.leftVal = index * this.imgWidth
				this.imgShow = index
			},
		},

		mounted: function() {
			this.listSwiper();
			this.listTPXW();
			this.listTZGG();
			this.listZWDT();
			this.listDJDT();
			this.listFZXC();
			this.listJJJC();
			this.listWMCJ();
			this.listWNFW();
			this.listJGSZ();
			this.奥力给()
		}
	};
</script>
<style lang="scss" scoped>
	.cover{
		background: url(http://admission-46879.oss-cn-hangzhou.aliyuncs.com/upload/railroad/bgcover.png) no-repeat center center;
	}
	.indexNav{
		margin-top: 0px;
	}
	
	.imgTitle {
		position: absolute;
		top: 300px;
		left: 0;
		right: 0;
		maxwidth: 450px;
		height: 95px;
		text-align: center;
		background-color: rgba(233, 234, 236, 1);
		padding: 16px 0px;

		.text {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: rgba(16, 16, 16, 1);
			font-size: 24px;
			line-height: 35px;
			font-family: 'SemiBold';
		}

		.date {
			color: rgba(51, 51, 51, 1);
			font-size: 16px;
			text-align: right;
			margin-top: 14px;
			margin-right: 14px;
			font-family: 'Regular';
		}

		.show {
			display: block;
		}

		.hide {
			display: none;
		}
	}


	/* 图片容器样式 */
	.SwiperBox {
		margin-top: 15px;
		position: relative;
		width: 450px;
		height: 500px;
		box-sizing: border-box;
		cursor: pointer;
		overflow: hidden;
		
		.title {
			height: 100px;
			background-color: rgba(233, 234, 236, 1);
			padding: 16px 20px;
		     width: 450px;
			.text {
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: rgba(16, 16, 16, 1);
				font-size: 24px;
				line-height: 35px;
				font-family: 'SemiBold';
			}
		
			.date {
				
				color: rgba(51, 51, 51, 1);
				font-size: 16px;
				text-align: right;
				margin-top: 10px;
				font-family: 'Regular';
			}
		
			.show {
				display: block;
			}
		
			.hide {
				display: none;
			}
		}
		@media screen and (max-width: 1250px) {
		    .title {
				width: 410px;
		    }
		};
	}

	.imgBox {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 450px;
		height: 500px;
		display: flex;
		justify-content: flex-start;
	}

	/* 图片默认样式 */
	.imgBox img {
		flex-shrink: 0;
		width: 450px;
		height: 300px;
	}

	/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
	.leftBtn,
	.rightBtn {
		position: absolute;
		top: 31%;
		transform: translateY(-50%);
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(109, 109, 109, 0.445);
		color: #fff;
		border-radius: 50%;
		cursor: pointer;
		font-size: 12px;
		font-weight: 500;
	}

	.leftBtn {
		left: 10px;
	}

	.rightBtn {
		right: 10px;
	}

	/* 下方指示器盒子 */
	.instBox {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 280px;
		display: flex;
	}

	/* 小圆点 */
	.inst {
		width: 10px;
		height: 10px;
		border: 1px solid #ccc;
		margin-right: 8px;
		background: #fff;
		border-radius: 50%;
		cursor: pointer;
	}

	.inst:last-child {
		margin-right: 0px;
	}

	.instActv {
		border: 1px solid #ff0000;
		background: #ff0000;
	}

	#app {
		width: 100%;
		display: flex;
		justify-content: center;
	}
</style>

<style lang="scss" scoped>
	.main {
		min-width: 1139px;
		width: 100%;

	}


	.content {

		max-width: 1220px;
		min-height: calc(100vh - 150px);
		margin: 40px auto;

		.content-item1 {
			display: flex;

			.swiper-box {
				width: 36%;

				/deep/.el-carousel__container {
					height: 421px;

					img {
						height: 326px !important;
					}
				}

				/deep/.el-carousel__indicators--horizontal {
					bottom: 96px;
				}

				/deep/.el-carousel__arrow {
					top: 38.71%;
				}

				/deep/.el-carousel__button {
					border-radius: 999px !important;
					height: 8px;
					width: 8px;
					bottom: 196px !important;
				}

				/deep/.el-carousel__indicator.is-active button {
					background-color: black;
				}

				.el-carousel__item h3 {
					color: #475669;
					font-size: 14px;
					opacity: 0.75;
					line-height: 150px;
					margin: 0;
				}

				.el-carousel__item:nth-child(2n) {
					background-color: #99a9bf;
				}

				.el-carousel__item:nth-child(2n + 1) {
					background-color: #d3dce6;
				}


				
			}

			.news {

				width: 60%;
				margin-left: 40px;

				.title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 12px;
					border-bottom: 1px solid rgba(187, 187, 187, 1);

					.newsphoto {
						font-family: 'SemiBold';
						color: rgba(51, 51, 51, 1);
						font-size: 20px;
						line-height: 28px;
						display: flex;
						align-items: center;

						img {
							width: 8px;
							height: 8px;
							vertical-align: middle;
							margin-right: 11px;
						}
					}

					.more {
						color: rgba(31, 74, 153, 1);
						font-size: 16px;
						line-height: 20px;
						display: flex;
						align-items: center;
						cursor: pointer;
						font-family: 'Regular';

						img {
							width: 20px;
							height: 20px;
							margin-left: 4px;
						}
					}
				}

				.newsGroup {
					margin-top: 20px;
					cursor: pointer;

					.item {
						display: flex;
						justify-content: space-between;
						line-height: 44px;
						border-bottom: 1px solid rgba(232, 232, 232, 1);

						.headline {
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							width: 70%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}

						.date {
							color: rgba(119, 119, 119, 1);
							font-size: 18px;

						}
					}
				}
			}
		}

		.content-item2 {
			display: flex;
			margin-top: 40px;

			.list1,
			.list2 {
				width: 36%;

				.title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 12px;
					border-bottom: 1px solid rgba(187, 187, 187, 1);

					.newsphoto {
						color: rgba(51, 51, 51, 1);
						font-size: 20px;
						line-height: 28px;
						display: flex;
						align-items: center;
						font-family: 'SemiBold';

						img {
							width: 8px;
							height: 8px;
							vertical-align: middle;
							margin-right: 11px;
						}
					}

					.more {
						cursor: pointer;
						color: rgba(31, 74, 153, 1);
						font-size: 16px;
						line-height: 20px;
						display: flex;
						align-items: center;
						font-family: 'Regular';

						img {
							width: 20px;
							height: 20px;
							margin-left: 4px;
						}
					}
				}

				.headline-group {
					cursor: pointer;
					margin-top: 20px;
					font-family: 'Regular';

					.item {
						line-height: 44px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						color: rgba(51, 51, 51, 1);
						font-size: 18px;
						border-bottom: 1px solid rgba(232, 232, 232, 1);
					}
				}
			}

			.list2 {
				margin-left: 40px;
			}

			.serve {
				margin-left: 40px;
				flex: 1;

				.title {
					display: flex;
					align-items: center;
					padding-bottom: 12px;
					border-bottom: 1px solid rgba(187, 187, 187, 1);
					color: rgba(51, 51, 51, 1);
					font-size: 20px;
					line-height: 28px;
					font-family: 'SemiBold';

					img {
						width: 8px;
						height: 8px;
						vertical-align: middle;
						margin-right: 11px;
					}
				}

				.serveGroup {
					font-family: 'Medium';
					margin-top: 23px;
					cursor: pointer;

					.item {
						height: 47px;
						line-height: 47px;
						color: #fff;
						border-radius: 4px;
						background: linear-gradient(89.94deg,
								rgba(31, 74, 153, 1) -1.05%,
								rgba(27, 126, 242, 1) 99.55%);
						display: flex;
						align-items: center;
						margin-bottom: 8px;
						padding-left: 8px;
						img {
							width: 40px;
							height: 40px;
						}

						.text {
							margin-left: 10px;
						}
					}
				}
			}
		}

		.banner {
			height: 120px;
			margin-top: 20px;
			background-image: url('../assets//img/banner.png');
			background-repeat: no-repeat;
			background-size: cover;

			//  img {
			//     width: 100%;
			//     height: 120px;
			//  object-fit: cover;

			//  }

		}

		.content-item3 {
			display: flex;
			margin-top: 40px;

			.box {
				width: 76.5%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.list {
					width: 47.4%;
					margin-bottom: 40px;

					.title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-bottom: 12px;
						border-bottom: 1px solid rgba(187, 187, 187, 1);

						.newsphoto {
							font-family: 'SemiBold';
							color: rgba(51, 51, 51, 1);
							font-size: 20px;
							line-height: 28px;
							display: flex;
							align-items: center;

							img {
								width: 8px;
								height: 8px;
								vertical-align: middle;
								margin-right: 11px;
							}
						}

						.more {
							font-family: 'Regular';
							color: rgba(31, 74, 153, 1);
							font-size: 16px;
							line-height: 20px;
							display: flex;
							align-items: center;
							cursor: pointer;

							img {
								width: 20px;
								height: 20px;
								margin-left: 4px;
							}
						}
					}

					.headline-group {
						cursor: pointer;
						margin-top: 20px;

						.item {
							line-height: 44px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							color: rgba(51, 51, 51, 1);
							font-size: 18px;
							border-bottom: 1px solid rgba(232, 232, 232, 1);
						}
					}
				}
			}

			.serve {
				margin-left: 40px;
				flex: 1;

				.title {
					font-family: 'SemiBold';
					display: flex;
					align-items: center;
					padding-bottom: 12px;
					border-bottom: 1px solid rgba(187, 187, 187, 1);
					color: rgba(51, 51, 51, 1);
					font-size: 20px;
					line-height: 28px;

					img {
						width: 8px;
						height: 8px;
						vertical-align: middle;
						margin-right: 11px;
					}
				}

				.serveGroup {
					font-family: 'Medium';
					cursor: pointer;
					margin-top: 23px;

					.item {
						height: 47px;
						line-height: 47px;
						color: #fff;
						border-radius: 4px;
						background: linear-gradient(89.94deg,
								rgba(31, 74, 153, 1) -1.05%,
								rgba(27, 126, 242, 1) 99.55%);
						display: flex;
						align-items: center;
						margin-bottom: 8px;
						padding-left: 8px;

						img {
							width: 40px;
							height: 40px;
						}

						.text {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}

.img-fixed1{
	
	width: 10vw;
	height: 40vh;
	position: fixed;
	left: 10px;
	bottom: 15vh;
	z-index: 999;
	img{
		width:100%;
	}
}

.img-fixed2{
	width: 10vw;
	height: 40vh;
	position: fixed;
	right: 10px;
	bottom: 15vh;
	z-index: 999;
	img{
		width:100%;
	}
}
@media (min-width:1610px) {
  .img-fixed1{
  	width: 200px;
  	height: 200px;
  	position: fixed;
  	left: 20px;
  	bottom: 220px;
  	z-index: 999;
  	img{
  		width: 100%;
  	}
  }
  
  .img-fixed2{
  	width: 200px;
  	height: 200px;
  	position: fixed;
  	right: 20px;
  	bottom: 220px;
  	z-index: 999;
  	img{
  		width: 100%;
  	}
  }
}
@media (max-width:1300px) {
  .img-fixed1,.img-fixed2{
    display: none;
  }
  
  
}
@media (min-width:1900px) {
  .img-fixed1{
	  height: 300px;
    left: 6vw;
  }
  .img-fixed2{
	  height: 300px;
	  right:6vw;
  }
  
}
@media (min-width:2000px) {
  .img-fixed1{
	  width: 12vw;
	  height: 30vh;
    left: 13vw;
	 bottom:35vh;
  }
  .img-fixed2{
	  width: 12vw;
	  height: 30vh;
	  right:13vw;
	  bottom:35vh;
  }
  
}
</style>