<template>
	<!-- 头部 -->
	<div class="w">
		<div class="head ">
			<img src="../assets/img/logo.png" alt="" />
			<div class="title">
				<h3 class="chinese">荆<div></div>州<div></div>市<div></div>地<div></div>方<div></div>铁<div></div>路<div></div>有<div></div>限<div></div>公<div></div>司</h3>
				<h4 class="spell">
					Jingzhou Local Railway Company Limited
				</h4>
			</div>
			<div v-if="detail"></div>
			<div class="search-box" @click="toResult" v-else>
				<el-input placeholder="请输入内容" suffix-icon="el-icon-search" v-model="input">
				</el-input>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				input: "",
				detail: this.$route.path.includes('/searchResult/')
			}


		},
		methods: {
			toResult() {
				this.$router.push("/searchResult/")
			}
		}
	}
</script>

<style lang="scss" scoped>
	// 头部
	.w {
		background: url(http://admission-46879.oss-cn-hangzhou.aliyuncs.com/upload/railroad/bgcover.png) no-repeat center center;
		height: 325px;
		background-size: cover;
		margin: auto;
	}
	.head {

		display: flex;
		width: 1200px;
		padding-top: 10px;
		margin: auto;

		img {
			width: 76px;
			height: 76px;
			margin: auto 0;
		}

		.title {
			width: 425px;
			margin-left: 20px;

			.chinese {
				font-family: SemiBold;
				line-height: 28px;
				color: rgba(16, 16, 16, 1);
				font-size: 28px;

				div {
					display: inline-block;
					width: 1.7px;
				}
			}

			.spell {
				font-family: Regular;
				line-height: 24px;
				color: rgba(16, 16, 16, 1);
				font-size: 16px;

			}
		}

		.search-box {
			margin-top: 18px;
			margin-left: auto;

			/deep/.el-input__inner:focus {
				border-color: rgba(187, 187, 187, 1);
			}

			/deep/.el-input__icon {
				color: #999999;
				font-size: 20px;
			}
		}
	}
</style>